.contact {
    h1 {
        text-align: left;
        margin: 0;
        margin-bottom: 16px; }

    h3 {
        margin: 0;
        margin-bottom: 16px; }

    ul {
        @include rmliststyle;

        li {
            margin: 5px 0;

            p {
                display: inline;
                margin: 0; } } }

    @include mobile {
        .col-8 {
            order: 1; }

        h1 {
            text-align: right; } }

    @include portrait {
        .col-8 {
            order: 1; }

        h1 {
            text-align: right; } } }

.grecaptcha-badge {
    display: none; }
