.hero {
    background-image: url(../img/hero.jpg);
    background-size: cover;
    background-position: center;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 200px), 0 100%);
    min-height: 300px;

    @include mobile {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%); } }


.hero-claim {
    margin-bottom: -100px;
    h2 {
        font-size: 28px;
        color: #fff;
        text-align: left;
        background: $darkgray;
        padding: 30px 50px;
        margin: 0;
        @include dp2;

        @include tablet {
            font-size: 20px; } }

    @include mobile {
        margin-bottom: -50px;
        margin-top: -100px;

        h2 {
            font-size: 12px; } } }
