.team {
    background-color: $darkgray;
    background-size: cover;
    background-position: center;
    text-align: right;

    h1 {
        display: inline-block;
        color: $darkgray;
        margin-top: -50px;
        padding: 10px 50px;
        background-color: #ffffff;

        @include mobile {
            background: none;
            padding: 0;
            color: #ffffff; } } }
