$headerheight: 50px;

header.main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $headerheight;
    background: $ultradarkgray;
    border-bottom: 5px solid $primary-color;
    z-index: 10000;
    font-family: $heading-font;
    @include dp6;

    .navicon {
        display: none; }

    .logo {
        position: absolute;
        top: 10px;
        left: 0;
        height: $headerheight - 20px;

        @include tabletmobile {
            left: 50%;
            transform: translateX(-50%); }

        img {
            height: 100%;
            margin: 0 auto; } }

    nav.main-navigation {

        ul {
            @include rmliststyle;
            justify-content: flex-end;
            display: flex;
            li {
                a {
                    text-decoration: none;
                    color: #ffffff;
                    display: block;
                    line-height: $headerheight - 5px;
                    padding: 0 35px;
                    padding-top: 5px;
                    font-weight: bold;
                    text-transform: uppercase;

                    @include desktopsmall {
                        padding: 0 20px;
                        padding-top: 5px; }

                    &:hover, &:focus, &:active {
                        background: $darkgray; } } } } }


    .quickcontact {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);

        a {
            color: #ffffff;
            position: relative;
            text-decoration: none;
            display: block;
            line-height: $headerheight - 5px;
            padding: 0 35px;
            padding-top: 5px;
            padding-left: 55px;

            &::before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                background-image: url(../img/phone_icon.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 17px;
                left: 30px;
                pointer-events: none; } } }


    @include tabletmobile {
        .navicon {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            height: $headerheight - 20px;
            width: $headerheight - 20px;
            cursor: pointer;

            span {
                @include center;
                display: block;
                width: 100%;
                height: 3px;
                background: #ffffff;
                transition: background $superfast;

                &::before, &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: #ffffff;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: transform $superfast, top $superfast, bottom $superfast; }

                &::before {
                    top: -10px; }

                &::after {
                    bottom: -10px; } }

            &.active {
                span {
                    background: none;

                    &::before {
                        top: 0;
                        transform: translateX(-50%) rotate(-45deg); }

                    &::after {
                        bottom: 0;
                        transform: translateX(-50%) rotate(45deg); } } } }

        nav.main-navigation {
            position: fixed;
            top: $headerheight + 5px;
            left: 0;
            width: 100%;
            background: transparentize($darkgray, .01);
            height: 0;
            transition: height $superfast;
            overflow: hidden;
            text-align: center;

            &.active {
                height: calc(100% - 55px);

                ul {
                    padding-top: 50px;

                    li {
                        height: 70px; } } }

            ul {
                text-align: left;
                padding: 0px 20px 20px 20px;
                box-sizing: border-box;
                overflow-y: auto;
                transition: padding $superfast;
                text-align: center;
                display: block;

                li {
                    display: block;
                    font-size: 22px;
                    height: 30px;
                    transition: height $superfast;

                    a {
                        padding: 10px 0; } } }

            .quickcontact {
                position: relative;
                display: inline-block;
                top: auto;
                left: auto;
                margin: 0 auto;
                transform: none; } } } }
