.services {
    clip-path: none;
    font-size: 16px;

    @include desktopdesktophi {
        padding-top: 0; }


    .text-container {
        padding-bottom: 50px;
        padding-top: 40px; }

    .listscontainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }

    .listcontainer {
        width: 33%;
        box-sizing: border-box;
        padding: 40px 0px 40px 80px;
        text-align: left;

        @include mobile {
            width: 100%;
            padding: 20px; }
        @include tablet {
            padding: 20px 40px;
            padding-left: 0; }
        @include desktopsmall {
            padding: 20px 40px;
            padding-left: 0; }
        @include portrait {
            width: 100%; }

        h2 {
            position: relative;
            padding-left: 50px;
            margin-right: 50px;
            display: inline-block;
            font-weight: 600;

            &::before {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: $primary-color;
                background-size: 80%;
                background-position: center;
                background-repeat: no-repeat; } }

        ul {
            padding-left: 10px;
            text-align: left;
            white-space: normal;
            list-style: none;

            li {
                font-size: 16px;
                color: $darkgray;
                padding: 3px 0;
                margin-bottom: 12px; } }

        &:nth-of-type(1) h2::before {
            background-image: url(../img/flaschnerei.svg); }

        &:nth-of-type(2) h2::before {
            background-image: url(../img/fassade.svg); }

        &:nth-of-type(3) h2::before {
            background-image: url(../img/dach.svg); } } }
