$primary-color: #cc0000;

$lightgray: #eee;
$gray: #888;
$darkgray: #404040;
$ultradarkgray: #333;

$heading-font: 'Montserrat', sans-serif;
$font: 'Montserrat', sans-serif;

$rise: 200px;

$border-radius: 0;


$superfast: .125s ease-in-out;
