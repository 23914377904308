footer.main-footer {
    position: relative;
    background: $darkgray;
    padding-top: 200px;
    clip-path: polygon(0 200px, 100% 0, 100% 100%, 0 100%);

    @include mobile {
        padding-top: 100px;
        clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%); }


    .bar {
        //border-top: 3px solid $primary-color
        padding: 10px;
        color: #999;
        background: #222222;
        text-align: center;
        font-size: 14px;

        span, a {
            padding: 0 10px;
            color: #999; } }


    .partner-logos {
        margin-top: -60px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .logo {
            margin: 20px;
            width: 100px;
            height: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @include tablet {
                width: 80px;
                height: 80px; }

            @include mobile {
                width: 60px;
                height: 60px; } } } }
