@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(/themes/kleiss/assets/font/Montserrat-Light.ttf) format('truetype'); }

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(/themes/kleiss/assets/font/Montserrat-Medium.ttf) format('truetype'); }

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(/themes/kleiss/assets/font/Montserrat-Bold.ttf) format('truetype'); }

body {
    font-family: $font;
    font-size: 16px; }

h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: right;
    color: $gray;
    margin: 30px 0;
    font-family: $heading-font;
    text-transform: uppercase;

    @include mobile {
        font-size: 28px; } }

h2 {
    font-size: 28px;
    color: darken($gray, 4);
    font-family: $heading-font;

    @include mobile {
        font-size: 22px; } }

.button {
    appearance: none;
    border: none;
    margin-top: 30px;
    display: inline-block;
    padding: 10px 25px;
    text-decoration: none;
    color: #ffffff;
    background: $primary-color;
    border-radius: $border-radius;
    outline: none;
    cursor: pointer;

    &:hover, &:focus {
        background: lighten($primary-color, 5); }

    &:active {
        background: darken($primary-color, 5); } }


a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline; } }



.oc-text-bordered {
    border-top: solid 1px #222;
    border-bottom: solid 1px #222;
    padding: 10px 0; }
.oc-text-uppercase {
    text-transform: uppercase; }
.oc-text-gray {
    color: #aaa!important; }
.oc-text-spaced {
    letter-spacing: 1px; }
