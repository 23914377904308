.faq {
    @include noselect;
    background: $lightgray;

    h2 {
        font-size: 24px;
        font-weight: 600; }

    .faq-item {
        box-sizing: border-box;
        margin-top: 20px;
        border-left: 4px solid $primary-color;
        transition: background $superfast, border $superfast;
        &.hidden {
            transition-delay: .075s;
            background: #404040;
            border-color: transparent;
            .content-container {
                height: 0!important;

                .content {
                    transition-delay: .075s; } }

            .title h2 {
                color: #fff;
                transition-delay: .075s; } }

        .title {
            padding: 20px;
            cursor: pointer;
            h2 {
                margin: 0;
                transition: color $superfast; }

            &:hover h2 {
                text-decoration: underline; } }
        .content-container {
            transition: height $superfast;
            overflow: hidden;
            padding: 0 20px;

            .content {
                transition: color $superfast;
                color: #777;
                line-height: 22px;
                padding: 1px 0; } } } }
