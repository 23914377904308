.grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.top {
    align-items: flex-start; }

  .vertical-center {
    align-self: center; }

  &>div {
    position: relative; }

  .col-1 {
    width: 8.3333333333%; }

  .col-2 {
    width: 16.6666666666%; }

  .col-3 {
    width: 25%; }

  .col-4 {
    width: 33.3333333333%; }

  .col-5 {
    width: 41.6666666666%; }

  .col-6 {
    width: 50%; }

  .col-7 {
    width: 58.3333333333%; }

  .col-8 {
    width: 66.6666666666%; }

  .col-9 {
    width: 75%; }

  .col-10 {
    width: 83.3333333333%; }

  .col-11 {
    width: 91.6666666666%; }

  .col-12 {
    width: 100%; }

  @include portrait {
    .col-1p {
      width: 8.3333333333%; }

    .col-2p {
      width: 16.6666666666%; }

    .col-3p {
      width: 25%; }

    .col-4p {
      width: 33.3333333333%; }

    .col-5p {
      width: 41.6666666666%; }

    .col-6p {
      width: 50%; }

    .col-7p {
      width: 58.3333333333%; }

    .col-8p {
      width: 66.6666666666%; }

    .col-9p {
      width: 75%; }

    .col-10p {
      width: 83.3333333333%; }

    .col-11p {
      width: 91.6666666666%; }

    .col-12p {
      width: 100%; } }

  @include mobile {
    .col-1md {
      width: 8.3333333333%;
      position: relative; }

    .col-2md {
      width: 16.6666666666%;
      position: relative; }

    .col-3md {
      width: 25%;
      position: relative; }

    .col-4md {
      width: 33.3333333333%;
      position: relative; }

    .col-5md {
      width: 41.6666666666%;
      position: relative; }

    .col-6md {
      width: 50%;
      position: relative; }

    .col-7md {
      width: 58.3333333333%;
      position: relative; }

    .col-8md {
      width: 66.6666666666%;
      position: relative; }

    .col-9md {
      width: 75%;
      position: relative; }

    .col-10md {
      width: 83.3333333333%;
      position: relative; }

    .col-11md {
      width: 91.6666666666%;
      position: relative; }

    .col-12md {
      width: 100%;
      position: relative; } }

  @include tablet {
    .col-1td {
      width: 8.3333333333%; }

    .col-2td {
      width: 16.6666666666%; }

    .col-3td {
      width: 25%; }

    .col-4td {
      width: 33.3333333333%; }

    .col-5td {
      width: 41.6666666666%; }

    .col-6td {
      width: 50%; }

    .col-7td {
      width: 58.3333333333%; }

    .col-8td {
      width: 66.6666666666%; }

    .col-9td {
      width: 75%; }

    .col-10td {
      width: 83.3333333333%; }

    .col-11td {
      width: 91.6666666666%; }

    .col-12td {
      width: 100%; } } }

.grid.space {
  justify-content: space-between;

  &>.col-1, &>.col-2, &>.col-3, &>.col-4, &>.col-5, &>.col-6, &>.col-7, &>.col-8, &>.col-9, &>.col-10, &>.col-11, &>.col-12 {
    margin-bottom: 20px; }

  &>.col-1 {
    width: calc(8.3333333333% - 15px); }

  &>.col-2 {
    width: calc(16.6666666666% - 15px); }

  &>.col-3 {
    width: calc(25% - 15px); }

  &>.col-4 {
    width: calc(33.3333333333% - 15px); }

  &>.col-5 {
    width: calc(41.6666666666% - 15px); }

  &>.col-6 {
    width: calc(50% - 15px); }

  &>.col-7 {
    width: calc(58.3333333333% - 15px); }

  &>.col-8 {
    width: calc(66.6666666666% - 15px); }

  &>.col-9 {
    width: calc(75% - 15px); }

  &>.col-10 {
    width: calc(83.3333333333% - 15px); }

  &>.col-11 {
    width: calc(91.6666666666% - 15px); }

  &>.col-12 {
    width: 100%; }

  @include portrait {
    &>.col-1p {
      width: calc(8.3333333333% - 4px); }

    &>.col-2p {
      width: calc(16.6666666666% - 4px); }

    &>.col-3p {
      width: calc(25% - 4px); }

    &>.col-4p {
      width: calc(33.3333333333% - 4px); }

    &>.col-5p {
      width: calc(41.6666666666% - 4px); }

    &>.col-6p {
      width: calc(50% - 4px); }

    &>.col-7p {
      width: calc(58.3333333333% - 4px); }

    &>.col-8p {
      width: calc(66.6666666666% - 4px); }

    &>.col-9p {
      width: calc(75% - 4px); }

    &>.col-10p {
      width: calc(83.3333333333% - 4px); }

    &>.col-11p {
      width: calc(91.6666666666% - 4px); }

    &>.col-12p {
      width: 100%; } }

  @include mobile {
    &>.col-1md {
      width: calc(8.3333333333% - 4px); }

    &>.col-2md {
      width: calc(16.6666666666% - 4px); }

    &>.col-3md {
      width: calc(25% - 4px); }

    &>.col-4md {
      width: calc(33.3333333333% - 4px); }

    &>.col-5md {
      width: calc(41.6666666666% - 4px); }

    &>.col-6md {
      width: calc(50% - 4px); }

    &>.col-7md {
      width: calc(58.3333333333% - 4px); }

    &>.col-8md {
      width: calc(66.6666666666% - 4px); }

    &>.col-9md {
      width: calc(75% - 4px); }

    &>.col-10md {
      width: calc(83.3333333333% - 4px); }

    &>.col-11md {
      width: calc(91.6666666666% - 4px); }

    &>.col-12md {
      width: 100%; } }

  @include tablet {
    &>.col-1td {
      width: calc(8.3333333333% - 4px); }

    &>.col-2td {
      width: calc(16.6666666666% - 4px); }

    &>.col-3td {
      width: calc(25% - 4px); }

    &>.col-4td {
      width: calc(33.3333333333% - 4px); }

    &>.col-5td {
      width: calc(41.6666666666% - 4px); }

    &>.col-6td {
      width: calc(50% - 4px); }

    &>.col-7td {
      width: calc(58.3333333333% - 4px); }

    &>.col-8td {
      width: calc(66.6666666666% - 4px); }

    &>.col-9td {
      width: calc(75% - 4px); }

    &>.col-10td {
      width: calc(83.3333333333% - 4px); }

    &>.col-11td {
      width: calc(91.6666666666% - 4px); }

    &>.col-12td {
      width: 100%; } } }
