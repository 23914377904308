input[type="text"], input[type="email"], textarea {
    width: 100%;
    resize: none;
    box-sizing: border-box;
    padding: 10px 20px;
    margin-bottom: 10px;
    outline: none;
    background: #fafafa;
    border: none;
    border-radius: 0;
    appearance: none;

    &:focus {
        background: $lightgray; } }

input, button, select, textarea {
    font-family: $font!important;
    @include tabletmobile {
        font-size: 14px!important; } }

.alert-success {
    margin: 10px 0; }
