section {
    min-height: 400px;
    clip-path: polygon(0 200px, 100% 0, 100% calc(100% - 200px), 0 100%);
    padding: 200px 0;
    margin-bottom: -200px;

    @include mobile {
        min-height: 200px;
        clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
        padding: 50px 0;
        margin-bottom: -50px; } }
