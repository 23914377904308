.portfolio {
    padding: 0;
    position: relative;

    .grid {
        height: 1100px;
        clip-path: polygon(0 200px, 100% 0, 100% calc(100% - 200px), 0 100%);
        position: relative;

        @include tabletmobile {
            clip-path: none;
            height: auto!important;

            .col-3 {
                clip-path: polygon(-10px 50px, calc(100% + 10px) 0, calc(100% + 10px) calc(100% - 50px), -10px 100%);
                width: calc(100% - 20px);
                padding: 150px 0;
                margin-bottom: -50px;

                &.services {
                    margin-top: -2px;
                    margin-bottom: -52px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 50px 10%;
                    background: #555555;
                    color: #ffffff;
                    @include dp4;

                    .services.mobile {
                        display: block!important; }

                    h1 {
                        color: #ffffff; } } } }

        .col-3 {
            background-size: cover;
            background-position: center; } }

    .servicescontainer {
        clip-path: polygon(0 200px, 100% 0, 100% calc(100% - 200px), 0 100%);
        height: 1160px;
        width: 100%;
        position: absolute;
        top: -30px;
        left: 0;

        @include tabletmobile {
            display: none; }

        .services {
            position: absolute;
            top: 0;
            right: 25%;
            background: $ultradarkgray;
            padding: 150px 100px;
            box-sizing: border-box;
            width: 25%;
            height: 100%;
            @include dp4;
            color: $lightgray;

            @include desktopsmall {
                padding: 150px 60px; }

            @include tablet {
                padding: 120px 50px; }

            h1 {
                color: $lightgray; } } } }



