.slidersection {
    position: relative;
    height: 500px;
    overflow: hidden;
    max-height: 600px;

    @include desktopsmall {
        height: 400px; }

    @include tablet {
        height: 300px; }

    @include mobile {
        height: 200px!important; }

    @include desktopdesktophi {
        margin-bottom: 0; } }


.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    white-space: nowrap;
    font-size: 0;


    .slide {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat; } }

.controls {
    .arrow-left, .arrow-right {
        position: absolute;
        top: 50%;
        color: #ffffff;
        font-size: 20px;
        z-index: 10;
        transform: translate(0, -50%);

        img {
            height: 40px; } }

    .arrow-left {
        left: 20px;
        padding-top: 200px; }
    .arrow-right {
        right: 20px;
        padding-bottom: 200px; }

    @include tablet {
        .arrow-left {
            padding-top: 100px; }

        .arrow-right {
            padding-bottom: 100px; } }

    @include mobile {
        .arrow-left {
            padding-top: 50px; }

        .arrow-right {
            padding-bottom: 50px; } } }

.slidersection .container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 0);

    .text-container {
        position: absolute;
        top: 0;
        right: 0;
        background: transparentize($ultradarkgray, .1);
        padding: 150px 100px 250px 100px;
        box-sizing: border-box;
        width: 30%;
        max-height: 100%;
        @include dp4;
        color: $lightgray;
        overflow-x: auto;

        @include desktopsmall {
            padding: 150px 60px 250px 60px; }

        @include tablet {
            padding: 120px 50px 220px 50px; }

        @include mobile {
            height: 400px;
            display: none; }

        h1 {
            color: $lightgray; } } }


.mobileservices {
    @include mobile {
        display: block!important;
        background: $darkgray;
        color: $lightgray;

        .container {
            padding: 20px 0; } } }
