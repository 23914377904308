.container {
    position: relative;
    margin: 0 auto;
    width: 50%;

    @include mobile {
        width: 80%; }

    @include tablet {
        width: 80%; } }
