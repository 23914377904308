.about {
    color: #777;
    .text {
        padding-top: 50px; }
    .meisterbrief {
        background-size: contain;
        background-position: right top;
        background-repeat: no-repeat;
        min-height: 300px;

        @include mobile {
            background-position: left top; } }

    a {
        display: block; }

    img {
        width: 100%; } }

