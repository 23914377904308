@mixin mobile {
	@media screen and (max-width: 699px) {
		@content; } }

@mixin tablet {
	@media screen and (min-width: 700px) and (max-width: 1399px) {
		@content; } }

@mixin tabletmobile {
	@media screen and (max-width: 1399px) {
		@content; } }

@mixin desktopsmall {
	@media screen and (min-width: 1400px) and (max-width: 1629px) {
		@content; } }

@mixin desktop {
	@media screen and (min-width: 1630px) and (max-width: 2099px) {
		@content; } }

@mixin tabletdesktophi {
	@media screen and (min-width: 700px) {
		@content; } }

@mixin desktophi {
	@media screen and (min-width: 2100px) {
		@content; } }

@mixin desktopdesktophi {
	@media screen and (min-width: 1400px) {
		@content; } }

@mixin portrait {
	@media screen and (orientation:portrait) {
		@content; } }

@mixin landscape {
	@media screen and (orientation:landscape) {
		@content; } }
