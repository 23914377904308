@mixin center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%,-50%,0); }

@mixin noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

@mixin rmliststyle {
	padding: 0;
	margin: 0;
	border: 0;
	list-style: none; }

// Shadows

@mixin dp1 {
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0px rgba(0,0,0,0.12), 1px 0px 3px 0px rgba(0,0,0,0.2); }
@mixin dp2 {
  box-shadow: 0 0 4px 0 rgba(0,0,0, 0.14), 0 3px 4px 0 rgba(0,0,0, 0.12), 0 1px 5px 0 rgba(0,0,0, 0.20); }
@mixin dp3 {
  box-shadow: 0 3px 3px 0 rgba(0,0,0, 0.14), 0 3px 4px 0 rgba(0,0,0, 0.12), 0 1px 8px 0 rgba(0,0,0, 0.20); }
@mixin dp4 {
  box-shadow: 0 2px 4px 0 rgba(0,0,0, 0.14), 0 4px 5px 0 rgba(0,0,0, 0.12), 0 1px 10px 0 rgba(0,0,0, 0.20); }
@mixin dp6 {
  box-shadow: 0 6px 10px 0 rgba(0,0,0, 0.14), 0 1px 18px 0 rgba(0,0,0, 0.12), 0 3px 5px 0 rgba(0,0,0, 0.20); }
@mixin dp8 {
  box-shadow: 0 8px 10px 1px rgba(0,0,0, 0.14), 0 3px 14px 3px rgba(0,0,0, 0.12), 0 4px 15px 0 rgba(0,0,0, 0.20); }
@mixin dp9 {
  box-shadow: 0 9px 12px 1px rgba(0,0,0, 0.14), 0 3px 16px 2px rgba(0,0,0, 0.12), 0 5px 6px 0 rgba(0,0,0, 0.20); }
@mixin dp12 {
  box-shadow: 0 12px 17px 2px rgba(0,0,0, 0.14), 0 5px 22px 4px rgba(0,0,0, 0.12), 0 7px 8px 0 rgba(0,0,0, 0.20); }
@mixin dp16 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0, 0.14), 0 6px 30px 5px rgba(0,0,0, 0.12), 0 8px 10px 0 rgba(0,0,0, 0.20); }
@mixin dp24 {
  box-shadow: 0 24px 38px 3px rgba(0,0,0, 0.14), 0 9px 46px 8px rgba(0,0,0, 0.12), 0 11px 15px 0 rgba(0,0,0, 0.20); }
@mixin text-shadow {
	text-shadow: 0 2px rgba(0,0,0,0.1); }

